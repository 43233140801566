'use client';
import { useState, useEffect } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import DonateButton from '@/src/app/(donation pages)/campaign/[slug]/DonateButton';
import { CampaignShareDrawerWrapper } from '../ShareDrawer/CampaignShareDrawerWrapper';
import config from '@/src/config';

interface StickyFooterProps {
  campaignId: number;
  slug: string;
  donateButtonText: string;
  campaignEndDate: Date;
  campaignStatus: number;
}

const StickyFooter = ({
  campaignId,
  donateButtonText,
  slug,
  campaignEndDate,
  campaignStatus,
}: StickyFooterProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 650) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`flex flex-col h-[140px] sticky bottom-0 lg:hidden ${
          !isVisible && '-z-10'
        }`}
      >
        <div
          className={`pt-6 pb-4 px-6 bg-rebuild-gray-100 transition w-full transform flex flex-col gap-3 shadow-md-inverse ${
            isVisible ? 'translate-y-0 animate-slide-up' : 'translate-y-full'
          }`}
        >
          <div className="flex gap-2">
            <Button
              className={`${buttonVariants({
                variant: 'outlineGray',
              })}`}
              size="lg"
              onClick={toggleDrawer}
            >
              <span>SHARE</span>
            </Button>
            <DonateButton
              slug={slug}
              campaignId={campaignId}
              buttonText={donateButtonText}
              campaignEndDate={campaignEndDate}
              campaignStatus={campaignStatus}
            />
          </div>
          <p className="text-gray-500 flex items-center justify-center text-xs">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-2"
            >
              <path
                d="M8 6.00006V8.50006M8 1.80957C6.56634 3.16732 4.63042 4.00007 2.5 4.00007C2.46615 4.00007 2.43235 3.99986 2.3986 3.99944C2.13993 4.78618 2 5.6268 2 6.5001C2 10.2278 4.54955 13.36 8 14.2481C11.4505 13.36 14 10.2278 14 6.5001C14 5.6268 13.8601 4.78618 13.6014 3.99944C13.5677 3.99986 13.5339 4.00007 13.5 4.00007C11.3696 4.00007 9.43366 3.16732 8 1.80957ZM8 10.5001H8.005V10.5051H8V10.5001Z"
                stroke="#4E5562"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>
              Backed by our{' '}
              <a href={`${config().lg3BaseUrl}/safety`} className="underline">
                Trust & Safety guarantee
              </a>
            </span>
          </p>
        </div>
      </div>
      <CampaignShareDrawerWrapper
        slug={slug}
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        useUserIdAsSrc
      />
    </>
  );
};

export default StickyFooter;
