'use client';

import { viewableStatuses } from '@/src/lib';
import { CampaignService } from '@/src/lib/api';
import { useAuthHook } from '@/src/lib/store/auth.store';
import { campaignAdminBasicInfoResponse, ProjectStatus } from '@/src/types';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export default function SplashScreen({
  campaignId,
  slug,
  campaignStatus,
  campaignViewableOnlyByAdmin,
}: {
  campaignId: number;
  slug: string;
  campaignStatus: number;
  campaignViewableOnlyByAdmin: boolean;
}) {
  const { user, loading } = useAuthHook();
  const router = useRouter();
  const [showSplash, setShowSplash] = useState(true);
  const campaignViewableByEveryone =
    !campaignViewableOnlyByAdmin && viewableStatuses.includes(campaignStatus);

  useEffect(() => {
    if (campaignViewableByEveryone) {
      console.debug('Campaign is viewable by everyone');
      setShowSplash(false);
      return;
    }

    // at this point, only logged in users are able to view the campaign
    // whether that's the CC, a team member, or an admin
    if (loading) return;
    if (!user) {
      console.debug(
        `Campaign {${slug}} (status ${campaignStatus}) is only viewable by a logged in user, redirecting to the discover page.`,
      );
      router.replace('/v4/discover');
      return;
    }

    const { 'is-admin': isAdmin } = user;

    // if user is admin, they can see everything
    if (isAdmin) {
      console.debug('User is an admin');
      setShowSplash(false);
      return;
    }

    if (campaignViewableOnlyByAdmin && !isAdmin) {
      console.debug(
        `Campaign {${slug}} (status ${campaignStatus}) is only viewable by admins, so user must be logged in to an admin account to access it.`,
      );
      router.replace('/v4/discover');
      return;
    }

    // user is not admin, and the campaign isn't viewable by everyone
    // so we need to be sure the user is the CC or a team member
    CampaignService.projectAdminBasicInfo(campaignId).then((res) => {
      const { success, message, result } = res;
      if (!success) {
        console.error(message);
        return;
      }
      const { canEdit } = result;
      if (!canEdit) {
        console.debug(
          `Campaign with slug {${slug}} and status {${campaignStatus}} is only viewable by admins or the CC. Redirecting to discover page.`,
        );
        router.replace('/v4/discover');
        return;
      }
      console.debug('User is the CC or a team member');
      setShowSplash(false);
    });
  }, [loading]);

  return (
    <>
      {showSplash && (
        <section
          className={`fixed top-0 left-0 w-full h-full bg-white z-50 flex flex-col items-center justify-center`}
        >
          <img
            src="https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136"
            className="w-40 animate-pulse"
          />
          <p>Loading campaign...</p>
        </section>
      )}
    </>
  );
}
