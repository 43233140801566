/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Button, buttonVariants } from '../ui/rebuild/Button';
import styles from './styles.module.css';

const CampaignAbout = ({
  aboutTitle,
  aboutDesc,
  readMore,
}: {
  aboutTitle?: string;
  aboutDesc: string;
  readMore?: boolean;
}) => {
  const [isReadMore, setIsReadMore] = useState(readMore);
  const handleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useEffect(() => {
    if (readMore === undefined) setIsReadMore(window.innerWidth > 1024);
  }, []);
  return (
    <section>
      {aboutTitle && aboutTitle.length > 0 && (
        <>
          <h1 className="text-2xl text-rebuild-dark font-bold">{aboutTitle}</h1>
          <hr className="my-6 border-rebuild-gray-300" />
        </>
      )}
      <div
        className={`${
          isReadMore ? 'h-auto' : 'max-h-[440px]'
        } overflow-y-hidden relative`}
      >
        <div
          dangerouslySetInnerHTML={{ __html: aboutDesc }}
          id="fundraiser-description"
          className={`${styles.listStyles} [&>p]:mb-6 [&>p]:break-words [&_img]:mb-3`}
        />
        {!isReadMore && (
          <div
            style={{
              background:
                'linear-gradient(0deg, #FFF 40.65%, rgba(255, 255, 255, 0.00) 104.84%)',
            }}
            className="bottom-0 w-full absolute h-[70px]"
          ></div>
        )}
      </div>
      <Button
        className={`${buttonVariants({
          variant: 'ghost',
        })} w-full text-primary ${isReadMore && 'mt-4'}`}
        onClick={handleReadMore}
      >
        {isReadMore ? 'Read less' : 'Read more'}
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isReadMore && 'rotate-180'} transition`}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.73017 7.70938C6.02875 7.42228 6.50353 7.43159 6.79063 7.73017L10.5 11.6679L14.2094 7.73017C14.4965 7.43159 14.9713 7.42228 15.2698 7.70938C15.5684 7.99647 15.5777 8.47125 15.2906 8.76983L11.0406 13.2698C10.8992 13.4169 10.704 13.5 10.5 13.5C10.296 13.5 10.1008 13.4169 9.95938 13.2698L5.70938 8.76983C5.42228 8.47125 5.43159 7.99647 5.73017 7.70938Z"
            fill="#4AA567"
          />
        </svg>
      </Button>
    </section>
  );
};

export default CampaignAbout;
