'use client';

import CampaignAbout from '@/src/components/CampaignAbout';
import { CampaignCommunity } from '@/src/components/CampaignCommunity';
import { CampaignUpdates } from '@/src/components/CampaignUpdates';
import { CommunitiesSlider } from '@/src/components/CommunitiesSlider';
import { GivingLevels } from '@/src/components/GivingLevels';
import ProgressBar from '@/src/components/ProgressBar';
import Tabs from '@/src/components/Tabs';
import { TrustAndSafety } from '@/src/components/TrustAndSafety';
import { Button, buttonVariants } from '@/src/components/ui/rebuild/Button';
import { GivingLevelsProps } from '@/src/types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { TabsSectionsProps } from './interfaces';
import { useSearchParams } from 'next/navigation';
import DonateButton from './DonateButton';
import { CampaignShareDrawerWrapper } from '@/src/components/ShareDrawer/CampaignShareDrawerWrapper';
import CommentsStream from '@/src/components/CampaignComments/CommentsStream';
import { useExchangeRateHook } from '@/src/lib/store/exchange-rate';

// to avoid hydration errors, we need to import CountdownTimer dynamically
import dynamic from 'next/dynamic';
import CountdownTimer from '@/src/components/CountdownTimer/CountdownTimer';
import GivingLevelCard from '@/src/components/GivingLevels/GivingLevelCard';
import CurrencySelector from '@/src/components/CurrencySelector';
// const CountdownTimer = dynamic(
//   () => import('@/src/components/CountdownTimer/CountdownTimer'),
//   { ssr: false },
// );

const TabsSections = ({
  campaignStatus,
  isActiveCampaign,
  aboutTitle,
  campaignId,
  campaignUrl,
  communities,
  campaignTitle,
  ccName,
  ccThumbnail,
  campaignCurrency,
  donateButtonText,
  givingLevels,
  progress,
  remainingDays,
  supporters,
  slug,
  timelineData,
  endDate,
}: TabsSectionsProps) => {
  const searchParams = useSearchParams();
  const src = searchParams.get('src');
  const isBeta = searchParams.get('beta');
  const tabs = [
    { name: 'About' },
    { name: 'Donors' },
    { name: 'Comments' },
    { name: 'Updates' },
  ];
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [givingAmount, setGivingAmount] = useState<number>(20);
  const [activeGivingLevel, setActiveGivingLevel] =
    useState<GivingLevelsProps>();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const [viewportWidth, setViewportWidth] = useState(0);
  const [countdownEnded, setCountdownEnded] = useState(false);
  const maxCampaignBannerHeight = 650;
  const currencyDetails = useExchangeRateHook(
    givingAmount,
    campaignCurrency,
    false,
  );
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > maxCampaignBannerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleCustomAmountInput = (e: ChangeEvent<HTMLInputElement>) => {
    setGivingAmount(Number(e.target.value));
  };

  const createRedirectUrl = (glId: number) => {
    const searchParams = new URLSearchParams();
    if (src) {
      searchParams.set('src', src);
    }
    searchParams.set('givingLevelId', glId.toString());

    return `/v4/checkout/${slug}?${searchParams.toString()}`;
  };

  return (
    <>
      <div className="bg-white">
        <div
          className={`sticky lg:fixed lg:w-full lg:left-0 top-0 bg-rebuild-gray-100 shadow-sm px-8 pt-1 z-30 lg:transition
        ${
          isVisible
            ? 'lg:translate-y-0 lg:animate-slide-down'
            : 'lg:-translate-y-full'
        }`}
        >
          <div className="lg:container lg:mx-auto lg:flex lg:justify-between lg:items-center gap-4">
            <div>
              <div className="lg:flex hidden justify-between ">
                <h3 className="font-bold line-clamp-2 mt-4 mb-3">
                  {campaignTitle}
                </h3>
              </div>
              <Tabs tabs={tabs} />
            </div>
            <div className="hidden lg:block">
              <ProgressBar
                backgroundColor="#E5E5E5"
                visualParts={[
                  {
                    percentage: progress,
                    color: '#4AA567',
                  },
                ]}
              />
              <div className="hidden lg:block mt-3">
                {(supporters.count || remainingDays) && (
                  <div className="text-sm lg:text-base text-rebuild-gray-500 lg:text-center">
                    {supporters && (
                      <>
                        <span className="text-black-400">
                          {supporters.count}
                        </span>{' '}
                        supporters,{' '}
                      </>
                    )}
                    {remainingDays > 1 && (
                      <>
                        <span className="text-black-400">{remainingDays}</span>{' '}
                        days left
                      </>
                    )}
                    {remainingDays <= 1 && (
                      <>
                        <CountdownTimer
                          endTime={endDate}
                          className="text-black-400 text-sm font-normal"
                          format="hh'h' : mm'm' : ss's'"
                          textDisplayedOnTimerEnd="Project closed"
                          onCountdownEnd={() => setCountdownEnded(true)}
                        />{' '}
                        {!countdownEnded && <span>left</span>}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div></div>
            </div>
            <div className="hidden lg:flex flex-row-reverse gap-2">
              <div className="min-w-[226px]">
                <DonateButton
                  slug={slug}
                  campaignId={campaignId}
                  campaignEndDate={endDate}
                  buttonText={donateButtonText}
                  campaignStatus={campaignStatus}
                />
              </div>
              <Button
                className={`${buttonVariants({
                  variant: 'outlineGray',
                })}`}
                size="lg"
                onClick={toggleDrawer}
              >
                <span>SHARE</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="lg:hidden">
          <Element name="About" className="p-8 pb-0">
            <CampaignAbout
              aboutTitle={aboutTitle}
              aboutDesc={timelineData.items[0]?.body}
            />
            {givingLevels?.length > 0 && (
              <>
                <hr className="my-8 border-rebuild-gray-300" />
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl text-neutral-900 font-bold">
                    Your giving amount
                  </h2>
                  <CurrencySelector variant="gray" />
                </div>
                <div className="flex flex-col gap-4">
                  {givingLevels.map((givingLevel, index) => (
                    <GivingLevelCard
                      key={index}
                      type="link"
                      givingLevel={givingLevel}
                      campaignCurrency={campaignCurrency}
                      redirectUrl={createRedirectUrl(givingLevel.ID)}
                    />
                  ))}
                </div>
              </>
            )}
          </Element>
          <hr className="my-8 border-rebuild-gray-300 mx-8" />
          <Element name="Donors" className="px-8">
            <CampaignCommunity
              slug={slug}
              supporters={supporters}
              campaignUrl={campaignUrl}
              campaignId={campaignId}
            />
          </Element>
          <hr className="my-8 border-rebuild-gray-300 mx-8" />
          <Element name="Comments">
            <CommentsStream campaignId={campaignId} />
          </Element>
          {communities.length > 0 && (
            <Element name="Communities" className="px-8">
              <CommunitiesSlider communities={communities} />
              <hr className="my-8 border-rebuild-gray-300 mx-8" />
            </Element>
          )}
          <Element name="Updates" className="px-8 pb-8">
            <CampaignUpdates
              timelineData={timelineData}
              thumbnail={ccThumbnail}
              name={ccName}
            />
          </Element>
        </div>
        {viewportWidth >= 768 && (
          <div className="lg:grid grid-cols-12 gap-24 hidden">
            <div className="col-span-8">
              <Element name="About" className="p-8 lg:p-0 lg:mb-12 pb-0">
                <CampaignAbout
                  aboutTitle={aboutTitle}
                  aboutDesc={timelineData.items[0]?.body}
                />
              </Element>
              <Element name="Comments" className="px-8 lg:px-0 pb-8">
                <section>
                  <CommentsStream campaignId={campaignId} />
                </section>
              </Element>
              <Element name="Updates" className="px-8 lg:px-0 pb-8">
                <CampaignUpdates
                  timelineData={timelineData}
                  thumbnail={ccThumbnail}
                  name={ccName}
                />
              </Element>
              <TrustAndSafety />
            </div>
            <div className="col-span-4">
              <div className="mb-12">
                {givingLevels?.length > 0 && (
                  <>
                    <div className="flex items-center justify-between mb-4">
                      <h2 className="text-xl text-neutral-900 font-bold">
                        Your giving amount
                      </h2>
                      <CurrencySelector variant="gray" />
                    </div>
                    <div className="flex flex-col gap-4">
                      {givingLevels.map((givingLevel, index) => (
                        <GivingLevelCard
                          key={index}
                          type="link"
                          givingLevel={givingLevel}
                          campaignCurrency={campaignCurrency}
                          redirectUrl={createRedirectUrl(givingLevel.ID)}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
              <Element name="Donors" className="px-8 lg:px-0">
                <div className="mb-12">
                  <CampaignCommunity
                    slug={slug}
                    supporters={supporters}
                    campaignUrl={campaignUrl}
                    campaignId={campaignId}
                  />
                </div>
                {communities.length > 0 && (
                  <CommunitiesSlider communities={communities} />
                )}
              </Element>
            </div>
          </div>
        )}
      </div>
      <CampaignShareDrawerWrapper
        slug={slug}
        isOpen={isDrawerOpen}
        onClose={toggleDrawer}
        useUserIdAsSrc
      />
    </>
  );
};

export default TabsSections;
