'use client';

import CurrencyFormattedNumber from '@/src/components/CurrencyNumber';
import { useExchangeRateHook } from '@/src/lib/store/exchange-rate';
import { Currency, GivingLevelsProps } from '@/src/types';
import { useEffect } from 'react';

type Props<T> =
  | {
      type: 'default';
      givingLevel: GivingLevelsProps;
      selectedGivingLevel: GivingLevelsProps | undefined;
      campaignCurrency: Currency;
      showClaimed?: boolean;
      selected: boolean;
      onSelect: (amount: number) => void;
    }
  | {
      type: 'link';
      givingLevel: GivingLevelsProps;
      campaignCurrency: Currency;
      redirectUrl: string;
      showClaimed?: boolean;
    };

// TODO: split return into separate elements to make it easier
export default function GivingLevelCard<T>(props: Props<T>) {
  const { type, givingLevel, campaignCurrency, showClaimed = true } = props;

  const {
    ID,
    Title,
    Description,
    Limit,
    PrizeAwarded,
    IncludeEmailNote,
    NoteEmail,
    RequireEmail,
    ChallengeAmount,
  } = givingLevel;

  const exchangeRateObject = useExchangeRateHook(
    ChallengeAmount,
    campaignCurrency,
    false,
  );

  useEffect(() => {
    if (type === 'link') return;
    const { selected, onSelect } = props;

    if (!selected) return;
    const { amount } = exchangeRateObject;
    onSelect(amount);
  }, []);

  useEffect(() => {
    if (type === 'link') return;
    const { selectedGivingLevel, onSelect } = props;
    if (selectedGivingLevel === givingLevel) {
      const { amount } = exchangeRateObject;
      onSelect(amount);
    }
  }, [exchangeRateObject]);

  return (
    <>
      {type === 'link' && props.redirectUrl && (
        <a href={props.redirectUrl}>
          <div className="block p-6 border shadow-sm border-rebuild-dark rounded-lg flex-shrink-0 cursor-pointer transition-colors duration-200 hover:bg-neutral-50 active:bg-neutral-100">
            <CurrencyFormattedNumber
              element="h2"
              className="text-2xl font-bold mb-3"
              amount={ChallengeAmount}
              currencyCode={campaignCurrency}
            />
            <p className="font-bold  text-rebuild-dark mb-1">{Title}</p>
            <p>{Description}</p>

            <div className="flex flex-col gap-1 mt-2">
              {showClaimed && (
                <>
                  {Limit > 0 && (
                    <>
                      {PrizeAwarded >= Limit && (
                        <p className="text-sm text-red-400">Sold out! </p>
                      )}

                      <p className="text-neutral-500 text-sm">
                        {PrizeAwarded} of {Limit} claimed
                      </p>
                    </>
                  )}
                  {Limit <= 0 && (
                    <p className="text-neutral-500 text-sm">
                      {PrizeAwarded} claimed
                    </p>
                  )}
                </>
              )}
              {RequireEmail && (
                <>
                  <p className="text-neutral-500 text-xs italic mt-2">
                    By choosing this Giving Level, you agree to share your email
                    with the campaign organizer.
                  </p>
                  {IncludeEmailNote && (
                    <p className="text-neutral-500 text-xs italic">
                      <strong>
                        Reason provided by the campaign organizer:{' '}
                      </strong>
                      {NoteEmail}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </a>
      )}

      {type === 'default' && (
        <>
          {((props.selectedGivingLevel &&
            props.selectedGivingLevel === givingLevel) ||
            !props.selectedGivingLevel) && (
            <div
              className="block p-6 border shadow-sm border-rebuild-dark rounded-lg flex-shrink-0 cursor-pointer transition-colors duration-200 hover:bg-neutral-50 active:bg-neutral-100"
              onClick={() => {
                props.onSelect(exchangeRateObject.amount);
              }}
            >
              <CurrencyFormattedNumber
                element="h2"
                className="text-2xl font-bold mb-3"
                amount={ChallengeAmount}
                currencyCode={campaignCurrency}
              />
              <p className="font-bold  text-rebuild-dark mb-1">{Title}</p>
              <p>{Description}</p>

              <div className="flex flex-col gap-1 mt-2">
                {showClaimed && (
                  <>
                    {Limit > 0 && (
                      <>
                        {PrizeAwarded >= Limit && (
                          <p className="text-sm text-red-400">Sold out! </p>
                        )}

                        <p className="text-neutral-500 text-sm">
                          {PrizeAwarded} of {Limit} claimed
                        </p>
                      </>
                    )}
                    {Limit <= 0 && (
                      <p className="text-neutral-500 text-sm">
                        {PrizeAwarded} claimed
                      </p>
                    )}
                  </>
                )}
                {RequireEmail && (
                  <>
                    <p className="text-neutral-500 text-xs italic mt-2">
                      By choosing this Giving Level, you agree to share your
                      email with the campaign organizer.
                    </p>
                    {IncludeEmailNote && (
                      <p className="text-neutral-500 text-xs italic">
                        <strong>
                          Reason provided by the campaign organizer:{' '}
                        </strong>
                        {NoteEmail}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
