import { CampaignComment, CampaignCommentsResponse } from '@/src/types';
import { OpenAPI } from '../api/v4/client/core/OpenAPI';
import { request } from '../api/v4/client/core/request';

export class CampaignCommentsService {
  public static add = async (
    comment: Omit<
      CampaignComment,
      'id' | 'createdAt' | 'updatedAt' | 'isHidden' | 'isDeleted'
    >,
  ): Promise<CampaignComment> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v4/donations/comment',
      body: comment,
    });
  };

  public static get = async (
    campaignId: number,
    limit: number,
    offset: number,
  ): Promise<CampaignCommentsResponse> => {
    return await request(OpenAPI, {
      method: 'GET',
      url: `/v4/campaigns/{campaignId}/comments`,
      path: { campaignId },
      query: {
        limit,
        offset,
      },
    });
  };

  public static update = async (
    campaignId: number,
    commentId: number,
    comment: CampaignComment,
  ): Promise<CampaignComment> => {
    return await request(OpenAPI, {
      method: 'PATCH',
      url: '/v4/campaigns/{campaignId}/comments/{commentId}',
      path: { campaignId, commentId },
      body: comment,
    });
  };

  public static delete = async (campaignId: number, commentId: number) => {
    return await request(OpenAPI, {
      method: 'DELETE',
      url: '/v4/campaigns/{campaignId}/comments/{commentId}',
      path: { campaignId, commentId },
    });
  };
}
